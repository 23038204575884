<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Vendors"
    />
    <template v-if="error">
      <v-banner
        single-line
        elevation="10"
      >
        <v-icon
          slot="icon"
          color="warning"
          size="36"
        >
          mdi-wifi-strength-alert-outline
        </v-icon>
        <div>
          There was a problem communicating to the server!
          <div class="code">
            <strong>
              {{ error }}
            </strong>
          </div>
        </div>
      </v-banner>
    </template>
    <v-card>
      <v-row dense>
        <v-col>
          <template>
            <v-row>
              <v-col
                class="xs"
                sm="6"
                md="4"
                lg="2"
                xl="1"
              >
                <v-menu
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="formattedStartDate"
                      label="Start Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    @input="startDateMenu = false"
                  />
                </v-menu>
              </v-col>
              <v-col
                class="xs"
                sm="6"
                md="4"
                lg="2"
                xl="1"
              >
                <v-menu
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="formattedEndDate"
                      label="End Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    @input="endDateMenu = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-card>
    <v-row dense>
      <v-col class="text-right">
        <v-btn
          color="primary"
          @click="loadVendors()"
        >
          Update
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="items.length > 0">
      <v-row>
        <v-col>
          <h2>
            {{ items.length }} Vendors
          </h2>
        </v-col>

        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-col class="text-right">
              <download-excel
                :data="parsedItems"
                type="csv"
                :name="fileName"
              >
                <v-icon
                  large
                  color="green darken-2"
                  v-on="on"
                >
                  mdi-microsoft-excel
                </v-icon>
              </download-excel>
            </v-col>
          </template>
          <span>Download</span>
        </v-tooltip>
      </v-row>
    </template>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :loading="loading"
            :hide-default-footer="true"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page="50"
            :sort-by="['name']"
          >
            <template #item.monthly_cost="{value}">
              {{ formatPrice(value) }}
            </template>
            <template v-slot:item.adf_leads="{ item }">
              <router-link
                :to="{ name: 'Leads', params: { vendor_id: item.id }, query: { start_date: startDate, end_date: endDate} }"
              >
                {{ formatNumber(item.adf_leads) }}
              </router-link>
            </template>
            <template v-slot:item.calls="{ item }">
              <router-link
                :to="{ name: 'Calls', params: { vendor_id: item.id }, query: { start_date: startDate, end_date: endDate} }"
              >
                {{ formatNumber(item.calls) }}
              </router-link>
            </template>
            <template #item.vdp_views="{value}">
              {{ formatNumber(value) }}
            </template>
            <template v-slot:item.deals="{ item }">
              <router-link
                :to="{ name: 'Vendor Deals', params: { vendor_id: item.id }, query: { start_date: startDate, end_date: endDate} }"
              >
                {{ item.deals }}
              </router-link>
            </template>

            <template #item.cpe="{value}">
              {{ formatPriceWithCents(value) }}
            </template>
            <template
              v-if="!loading"
              slot="body.append"
            >
              <tr>
                <th style="font-size: 1.2rem;">
                  <div class="ma-4 text-left">
                    Totals
                  </div>
                </th>
                <th style="font-size: 1.2rem;">
                  <div
                    class="ma-4 text-center"
                    font-size="24px"
                  >
                    {{ formatNumber(summary.adf_leads_sum) }}
                  </div>
                </th>
                <th style="font-size: 1.2rem;">
                  <div
                    class="ma-4 text-center"
                    font-size="24px"
                  >
                    {{ formatNumber(summary.calls_sum) }}
                  </div>
                </th>
                <th style="font-size: 1.2rem;">
                  <div
                    class="ma-4 text-center"
                    font-size="24px"
                  >
                    {{ formatNumber(summary.vdp_views_sum) }}
                  </div>
                </th>
                <th style="font-size: 1.2rem;">
                  <div
                    class="ma-4 text-center"
                    font-size="24px"
                  >
                    {{ formatNumber(summary.deals_sum) }}
                  </div>
                </th>
                <th style="font-size: 1.2rem;">
                  <div
                    class="ma-4 text-center"
                    font-size="24px"
                  >
                    {{ formatPrice(summary.monthly_cost_sum) }}
                  </div>
                </th>
                <th style="font-size: 1.2rem;">
                  <div
                    class="ma-4 text-center"
                    font-size="24px"
                  >
                    {{ formatNumber(summary.eps_sum) }}
                  </div>
                </th>
                <th style="font-size: 1.2rem;">
                  <div
                    class="ma-4 text-center"
                    font-size="24px"
                  >
                    {{ formatNumber(summary.zep_sum) }}
                  </div>
                </th>
                <th style="font-size: 1.2rem;">
                  <div
                    class="ma-4 text-center"
                    font-size="24px"
                  >
                    {{ formatNumber(summary.per_sum) }}
                  </div>
                </th>
                <th style="font-size: 1.2rem;">
                  <div
                    class="ma-4 text-center"
                    font-size="24px"
                  >
                    {{ formatPriceWithCents(summary.cpe_sum) }}
                  </div>
                </th>
                <th />
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import VendorService from '@/services/VendorService.js'
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { countColorsMixin } from '@/mixins/countColorsMixin.js'
  import { navigationMixin } from '@/mixins/navigationMixin.js'

  export default {
    name: 'VendorList',
    mixins: [formatterMixin, countColorsMixin, navigationMixin],
    data () {
      return {
        items: [],
        summary: [],
        search: '',
        loading: true,
        error: '',
        expanded: [],
        singleExpand: false,
        startDate: null,
        endDate: null,
        startDateMenu: false,
        endDateMenu: false,
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'Leads', value: 'adf_leads', align: 'center' },
          { text: 'Calls', value: 'calls', align: 'center' },
          { text: 'VDP Views', value: 'vdp_views', align: 'center' },
          { text: 'Deals', value: 'deals', align: 'center' },
          { text: 'Avg Monthly Cost', value: 'monthly_cost', align: 'center' },
          { text: 'EPS', value: 'eps', align: 'center' },
          { text: 'ZEP', value: 'zep', align: 'center' },
          { text: 'PER', value: 'per', align: 'center' },
          { text: 'CPE', value: 'cpe', align: 'center' },
        ],
      }
    },
    computed: {
      parsedItems () {
        const parsedHeaders = this.headers.map(h => h.value)
        if (this.items) {
          return this.items.map(i => pick(i, parsedHeaders))
        }
        return []
      },
      fileName () {
        return `${this.accountName
          .toLowerCase()
          .replace(' ', '_')}_vendors.xls`
      },
      formattedStartDate () {
        return this.formatDate(this.startDate)
      },
      formattedEndDate () {
        return this.formatDate(this.endDate)
      },
      ...mapGetters(['accountId', 'accountName']),
    },
    watch: {
      accountId () {
        this.loadVendors()
      },
    },
    created () {
      this.startDate = this.defaultStartDate()
      this.endDate = this.defaultEndDate()
      this.loadVendors()
    },
    methods: {
      loadVendors: function () {
        this.items = []
        this.loading = true
        var data = {
          startDate: this.startDate,
          endDate: this.endDate,
        }
        VendorService.getVendors(data)
          .then(response => {
            this.items = response.data.vendors
            this.summary = response.data.summary
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              this.error = errorMessage
            }
          })
      },
    },
  }
</script>
